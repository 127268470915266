import React, { useEffect, useState } from "react";
import { GrServices } from "react-icons/gr";
import { TbShoe } from "react-icons/tb";
import { formatCurrency } from "../../helper/formatCurrency";
import { FaMoneyBillWave, FaCartArrowDown } from "react-icons/fa";
import { TbTruckDelivery } from "react-icons/tb";

import { Card, Col, Row, Typography, Button, Modal, Input, Radio } from "antd";
import { useFormik } from "formik";
import moment from "moment";
import { fetchData, putData } from "../../helper/api";
import { toast } from "react-toastify";
import Expenses from "./Expenses";

const { Title } = Typography;

function ShiftDashboard(props) {
    const { dataShift, setShiftDashboardOpen, trigerFetchData, setTrigerFetchData } = props;
    const [statisticData, setStatisticData] = useState();
    const [paid, setPaid] = useState(0);
    const [unpaid, setUnpaid] = useState(0);
    const [expenses, setExpenses] = useState([]);

    const [radioButton, setRadioButton] = React.useState('overview');
    const [openModalEndShift, setOpenModalEndShift] = useState(false);


    const formik = useFormik({
        initialValues: {
            cashInDrawer: 0
        },
        onSubmit: values => {
            setShiftDashboardOpen(true)
            setOpenModalEndShift(false)
        }
    })

    useEffect(() => {
        if (dataShift) {
            console.log("dataShift", dataShift)
            setStatisticData(dataShift);
            setPaid(dataShift.paid);
            setUnpaid(dataShift.unpaid);
            fetchData(`/expenses-shift`, {
                params: {
                    shift_id: dataShift.id
                }
            })
                .then((response) => {
                    const data = response.data.data.totalAmount || 0
                    console.log("data", data)
                    setExpenses(data)
                })
                .catch((error) => {
                    console.log(error)
                })

        }
    }, [dataShift, trigerFetchData]);

    const statistics2 = [
        {
            name: "Total Revenue",
            value: formatCurrency(parseInt(statisticData?.sales || 0)),
            icon: <FaMoneyBillWave />,
        },
        {
            name: "Total Pengeluaran",
            value: formatCurrency(expenses) || 0,
            icon: <FaMoneyBillWave />,
        },
        {
            name: "Paid",
            value: formatCurrency(parseInt(paid)),
            icon: <FaMoneyBillWave />,
        },
        {
            name: "Unpaid",
            value: formatCurrency(parseInt(unpaid)),
            icon: <FaMoneyBillWave />,
        },
    ];

    const statistics = [
        {
            name: "Uang Tunai di Awal",
            value: formatCurrency(parseInt(statisticData?.cash_initial || 0)),
            icon: <FaMoneyBillWave />,
        },
        {
            name: "Uang Masuk Tunai",
            value: formatCurrency(parseInt(statisticData?.cash_expected || 0)),
            icon: <FaMoneyBillWave />,
        },
        {
            name: "Transfer Masuk",
            value: formatCurrency(parseInt(statisticData?.bank_transfer || 0)),
            icon: <FaMoneyBillWave />,
        },
        {
            name: "QRIS Masuk",
            value: formatCurrency(parseInt(statisticData?.qris || 0)),
            icon: <FaMoneyBillWave />,
        },
        {
            name: "Transaksi",
            value: statisticData?.transaction || 0,
            icon: <FaCartArrowDown />,
        },
        {
            name: "Delivery",
            value: statisticData?.delivery || 0,
            icon: <TbTruckDelivery />,
        },
        {
            name: "Item",
            value: statisticData?.item || 0,
            icon: <TbShoe />,
        },
        {
            name: "Service",
            value: statisticData?.service || 0,
            icon: <GrServices />,
        },
    ];

    const options = [
        {
            label: 'Overview',
            value: 'overview',
        },
        {
            label: 'Expenses',
            value: 'expenses',
        }
    ];

    const handleEndShift = () => {
        putData(`end-shifts/${dataShift.id}`, {
            cash_actual: parseInt(formik.values.cashInDrawer),
            expense: parseInt(expenses)
        })
            .then((data) => {
                setOpenModalEndShift(false);
                setShiftDashboardOpen(false)
                formik.setFieldValue('cashInDrawer', 0)
                toast.success("Shift Ended");
            })
    }

    return (
        <div>
            <Modal
                title="End Shift"
                open={openModalEndShift}
                onCancel={() => setOpenModalEndShift(false)}
                onOk={() => setOpenModalEndShift(false)}
                footer={null}
            >
                <div style={{ display: 'flex', justifyContent: "space-between" }}>
                    <label>Uang Tunai di Awal</label>
                    <label>{formatCurrency(parseInt(statisticData?.cash_initial || 0))}</label>
                </div>

                <div style={{ display: 'flex', justifyContent: "space-between" }}>
                    <label>Uang Tunai Masuk</label>
                    <label>{formatCurrency(parseInt(statisticData?.cash_expected))}</label>
                </div>

                <div style={{ display: 'flex', justifyContent: "space-between" }}>
                    <label>Total Pengeluaran</label>
                    <label>{formatCurrency(expenses)}</label>
                </div>

                <div className="font-bold text-lg mt-3" style={{ display: 'flex', justifyContent: "space-between" }}>
                    <label>Total Keseluruhan Uang Tunai</label>
                    <label>{
                        formatCurrency(parseInt(statisticData?.cash_expected)
                            + parseInt(statisticData?.cash_initial)
                            - parseInt(expenses))
                    }</label>
                </div>



                <div className="flex flex-col gap-2">
                    <p style={{ marginTop: "20px", width: "100%", fontSize: '15px', fontWeight: 'bold' }}>Masukkan Uang Tunai Sekarang</p>
                    <Input
                        placeholder="Actual Cash in Drawer"
                        style={{ width: "100%" }}
                        name="cashInDrawer"
                        value={formatCurrency(parseInt(formik.values.cashInDrawer) || 0)}
                        onChange={(event) => {
                            const value = event.target.value;
                            // Mengabaikan karakter selain digit (contoh: comma atau titik)
                            const numericValue = parseFloat(value.replace(/[^\d]/g, ''));

                            // Menangani nilai awal NaN
                            const newValue = isNaN(numericValue) ? 0 : numericValue;

                            formik.setFieldValue('cashInDrawer', newValue);
                        }}
                    />

                    <Button className="items-center flex justify-center text-white bg-blue-400" onClick={() => handleEndShift()}>End Shift</Button>
                </div>
            </Modal>

            <p className="text-2xl font-bold text-black" level={3}>{`Shift, ${moment(dataShift.start_time).format('DD MMMM YYYY HH:mm')}`}</p>
            <p className="mb-4" level={4}>{`Open by ${dataShift.employee_shift?.name}`}</p>

            <Radio.Group
                className="mb-4"
                block
                options={options}
                defaultValue={radioButton}
                onChange={(e) => setRadioButton(e.target.value)}
                optionType="button"
                buttonStyle="solid"

            />

            {radioButton === 'overview' && (
                <div>
                    <Row className="rowgap-vbox" gutter={[24, 0]}>
                        {statistics2.map((c, index) => (
                            <Col
                                key={index}
                                xs={24}
                                sm={24}
                                md={12}
                                lg={10}
                                xl={10}
                                className="mb-4"
                            >
                                <Card bordered={false} className="criclebox ">
                                    <div className="number">
                                        <Row align="middle" gutter={[24, 0]}>
                                            <Col xs={18}>
                                                <span>{c.name}</span>
                                                <Title level={3}>
                                                    {c.value ? c.value : 0}
                                                </Title>
                                            </Col>
                                            <Col xs={6}>
                                                <div className="icon-box flex flex-col items-center justify-center" style={{ fontSize: '20px' }}>{c.icon}</div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Card>
                            </Col>
                        ))}
                    </Row>

                    <Row className="rowgap-vbox" gutter={[24, 0]}>
                        {statistics.map((c, index) => (
                            <Col
                                key={index}
                                xs={24}
                                sm={24}
                                md={12}
                                lg={6}
                                xl={6}
                                className="mb-4"
                            >
                                <Card bordered={false} className="criclebox ">
                                    <div className="number">
                                        <Row align="middle" gutter={[24, 0]}>
                                            <Col xs={18}>
                                                <span>{c.name}</span>
                                                <Title level={3}>
                                                    {c.value ? c.value : 0}
                                                </Title>
                                            </Col>
                                            <Col xs={6}>
                                                <div className="icon-box flex flex-col items-center justify-center" style={{ fontSize: '20px' }}>{c.icon}</div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                    <Button className="items-center flex justify-center text-white bg-blue-400 w-[20%]" onClick={() => setOpenModalEndShift(true)}>End Shift</Button>
                </div>
            )}

            {radioButton === 'expenses' && (
                <div>
                    <Expenses
                        shift_id={dataShift.id}
                        user_id={dataShift.employee_shift?.id}
                        date={moment(dataShift.start_time).format('YYYY-MM-DD')}
                        trigerFetchData={trigerFetchData}
                        setTrigerFetchData={setTrigerFetchData}
                    />
                </div>
            )}
        </div>
    );
}

export default ShiftDashboard;