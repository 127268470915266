import React from 'react';
import dayjs from 'dayjs';


export const PrintOrderSummary = async (orders, dueDate) => {
    
    try {
        const options = {
            filters: [{ name: 'RPP02' }], // Sesuaikan dengan nama printer Anda
            optionalServices: ['e7810a71-73ae-499d-8c15-faa9aef0c3f2'], // Ganti dengan UUID layanan Bluetooth printer Anda
        };

        const device = await navigator.bluetooth.requestDevice(options);
        const server = await device.gatt.connect();
        const service = await server.getPrimaryService('e7810a71-73ae-499d-8c15-faa9aef0c3f2'); // Ganti dengan UUID layanan Bluetooth printer Anda
        const characteristic = await service.getCharacteristic('bef8d6c9-9c21-4c9e-b632-bd58c1009f9f'); // Ganti dengan UUID karakteristik Bluetooth printer Anda

        if (characteristic === null) {
            console.error('Bluetooth characteristic is null');
            return false;
        }

        const encoder = new TextEncoder();

        const printData = async (data) => {
            await characteristic.writeValue(encoder.encode(data));
        };
        
        // Hasilkan ringkasan order
        const completionDate = dayjs(dueDate).format('dddd, DD MMMM YYYY');
        let headerText = `LIST ORDERAN SELESAI\n ${completionDate}\n\n`;
        
        // Cetak header terlebih dahulu
        await printData(headerText);
        
        for (let index = 0; index < orders.length; index++) {
            const order = orders[index];
            const customerName = order.customer.name;
            const orderType = order.order_type;
            const catatan = order.customer.description;
            const itemCount = order.quantity;
            const serviceCount = order.order_details.length;
        
            let summaryText = `${index + 1}. ${customerName} (${itemCount} I, ${serviceCount} S)\n`;
            summaryText += `Tipe Order : ${orderType}\n`;
            summaryText += `Catatan : ${catatan}\n\n`;
            summaryText += `Status: \n`;
            summaryText += `[ ] Sudah Siap\n`;
            summaryText += `[ ] Selesai\n`;
            summaryText += `[ ] Sudah Bayar\n\n`;
        
            // Cetak setiap order satu per satu
            await printData(summaryText);
        }
        

        // Cetak ringkasan

        console.log('Cetak berhasil');
        return true;

    } catch (error) {
        console.error('Error saat mencetak:', error);
        return false;
    }
};

