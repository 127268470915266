import React from 'react';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { formatCurrency } from '../../../helper/formatCurrency';
import { Button } from 'antd';

const exportToExcel = (orders) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Laporan Transaksi');

    // Setup the headers
    const headers = [
        'No', 'Tanggal Masuk', 'Customer', 'Qty Item', 'Qty Treatment', 'Total Harga (Sebelum Discount)', 'Total Discount', 'Harga Akhir', '% Pendapatan UKM', 'Detail Treatment', 'Detail Item'
    ];
    worksheet.addRow(headers);

    // Style headers
    worksheet.getRow(1).eachCell((cell) => {
        cell.font = { bold: true };
        cell.alignment = { vertical: 'middle', horizontal: 'center' };
    });

    // Initialize totals
    let no = 1;
    let totalHarga = 0;
    let totalDiscount = 0;
    let totalPendapatanUKM = 0;
    let totalFinalTransaksi = 0;

    // Insert data
    orders.forEach((order) => {
        const entryDate = order.entry_date;
        const customerName = order.customer.name;
        const qtyItem = order.quantity;
        const qtyTreatment = order.order_details.length;
        const totalTransaksi = order.payment.total_price;
        const totalDiskon = order.payment.total_discount;
        const finalTransaksi = order.payment.final_price;
        const pendapatanUKM = finalTransaksi * 0.15;

        // Update totals
        totalHarga += parseInt(totalTransaksi);
        totalDiscount += parseInt(totalDiskon);
        totalFinalTransaksi += parseInt(finalTransaksi);
        totalPendapatanUKM += parseInt(pendapatanUKM);

        // Prepare treatment and item names
        let treatmentNames = [];
        let itemNames = [];
        order.order_details.forEach((detail) => {
            treatmentNames.push(detail.treatment.name);
            itemNames.push(`${detail.item.brand} ${detail.item.name}`);
        });
        const treatments = treatmentNames.join(', ');
        const items = itemNames.join(', ');

        const row = [
            no++,
            entryDate,
            customerName,
            qtyItem,
            qtyTreatment,
            formatCurrency(parseInt(totalTransaksi)),
            formatCurrency(parseInt(totalDiskon)),
            formatCurrency(parseInt(finalTransaksi)),
            formatCurrency(parseInt(pendapatanUKM)),
            treatments,
            items
        ];

        worksheet.addRow(row);
    });

    // Add total row
    const totalRow = [
        '', '', '', '', '', formatCurrency(totalHarga), formatCurrency(totalDiscount), formatCurrency(totalFinalTransaksi), formatCurrency(totalPendapatanUKM), '', ''
    ];
    const totalRowIndex = worksheet.addRow(totalRow).number;

    // Style total row
    worksheet.getRow(totalRowIndex).eachCell((cell) => {
        cell.font = { bold: true };
        cell.alignment = { vertical: 'middle', horizontal: 'center' };
    });

    // Adjust column widths
    worksheet.columns.forEach(column => {
        column.width = 20;
    });

    // Save the workbook to a file
    workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer]), 'Laporan_Transaksi.xlsx');
    });
};

const ExportExcel = ({ orders }) => {
    return (
            <Button onClick={() => exportToExcel(orders)}>Exprot to Excel</Button>
    );
};

export default ExportExcel;
