import { Button, Card, Empty, Form, Input, Modal, Table } from 'antd';
import React, { useEffect } from 'react';
import { fetchData, postData, putData } from '../../helper/api';
import moment from 'moment';
import { date } from 'yup';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { formatCurrency } from '../../helper/formatCurrency';
import { parse } from 'date-fns';

const Expenses = (props) => {
    const { shift_id, user_id, date, trigerFetchData, setTrigerFetchData} = props
    const [dataExpenses, setDataExpenses] = React.useState([])
    const [openModalAddExpenses, setOpenModalAddExpenses] = React.useState(false)
    


    const formik = useFormik({
        initialValues: {
            user_id: user_id,
            shift_id: shift_id,
            name: '',
            amount: '',
        },
        onSubmit: values => {
            if (formik.values.id) {
                putData(`/expenses/${formik.values.id}`, values)
                    .then((response) => {
                        formik.resetForm();
                        toast.success('Expense updated successfully');
                        setOpenModalAddExpenses(false);
                        setTrigerFetchData(!trigerFetchData);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } else {
                postData('/expenses-shift', values)
                    .then((response) => {
                        formik.resetForm();
                        toast.success('Expense added successfully');
                        setOpenModalAddExpenses(false);
                        setTrigerFetchData(!trigerFetchData);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        }
    })

    useEffect(() => {
        // Get data expenses
        fetchData('/expenses-shift', {
            params: {
              shift_id: shift_id
            }
        })
            .then((response) => {
                const data = response.data.data.expenses
                const moveData = data.map((item) => {
                    return {
                        ...item,
                        date_time: item.createdAt ? moment(item.createdAt).format('DD MMM YYYY HH:mm:ss') : '-',
                        amount: parseInt(item.amount || 0),
                    }
                })
                setDataExpenses(moveData)
            })
            .catch((error) => {
                console.log(error)
            })
    }, [trigerFetchData])

    const columnsShift = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Date Time',
            dataIndex: 'date_time',
            key: 'date_time',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            render: (text, record) => (
                <>{formatCurrency(record.amount)}</>
            )
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => (
                <div>
                    <Button
                        className="bg-blue-500 text-white"
                        onClick={() => {
                            formik.setValues(record)
                            setOpenModalAddExpenses(true)
                        }}
                    >
                        Edit
                    </Button>
                </div>
            ),
        },
    ]

    return (
        <div>

            <Modal
                title="Tambah Pengeluaran"
                open={openModalAddExpenses}
                onCancel={() => setOpenModalAddExpenses(false)}
                onOk={() => setOpenModalAddExpenses(false)}
                footer={null}
            >
                <Form layout="vertical" onSubmit={formik.handleSubmit}>
                    <Form.Item label="Name">
                        <Input
                            name="name"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                        />
                    </Form.Item>
                    <Form.Item label="Amount">
                        <Input
                            name="amount"
                            value={formik.values.amount}
                            onChange={formik.handleChange}
                        />
                    </Form.Item>

                    <div className="flex justify-center">
                        <Button className="bg-blue-500 text-white" onClick={
                            formik.handleSubmit
                        }>Tambah Pengeluaran</Button>
                    </div>

                </Form>


            </Modal>



            <div className='flex justify-start mb-2'>
                <Button className="bg-blue-500 text-white"
                    onClick={() => 
                    {
                        formik.resetForm()
                        setOpenModalAddExpenses(true)
                    }
                    }>Tambah Pengeluaran</Button>
            </div>

            <Card>
                <div className="table-responsive" >
                    <Table
                        columns={columnsShift}
                        dataSource={dataExpenses}
                        className="ant-border-space"
                        locale={{
                            emptyText: <Empty description="Data not found" />,
                        }}
                        pagination={{
                            position: ['bottomCenter'],
                            pageSize: 10,
                            showSizeChanger: true,
                            pageSizeOptions: ['5', '10', '20', '30', '40'],
                            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                        }}
                    />
                </div>
            </Card>
        </div>
    );
};

export default Expenses;